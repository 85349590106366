/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */

import {graphql} from 'gatsby'
import {jsx, Styled} from 'theme-ui'
import {Layout, SEO, Form} from '../components'
import {Container} from '../elements'
import Navigation from '../components/navigation'

const KontaktSite = ({
  data: {Kontakt, prismicForm, i18nPathname},
  location,
}) => {
  const {lang} = Kontakt
  return (
    <Layout>
      <Navigation lang={lang} i18nPathname={i18nPathname.nodes} />
      <SEO data={Kontakt.data} pathname={location.pathname} />
      <div
        sx={{
          mb: [(t) => t.layout.separation.mobile, '', '8rem'],
        }}
      >
        <div
          sx={{
            backgroundColor: 'primary',
            p: ['7rem 1.5rem 5rem 1.5rem', '', '12rem 1.5rem 8rem 1.5rem'],
          }}
        >
          <div
            sx={{
              maxWidth: '70rem',
              m: '0 auto',
            }}
          >
            <h1
              sx={{
                fontSize: ['1rem', '', '1.5rem'],
                lineHeight: 1.4,
                color: '#fde3ce',
                m: ['0 0 0.4rem 0', '', '0'],
              }}
            >
              {Kontakt.data.header_title}
            </h1>
            <h2
              sx={{
                fontSize: ['2rem', '', '2.5rem'],
                lineHeight: 1.25,
                color: (t) => t.colors.gray[1],
                m: 0,
              }}
            >
              {Kontakt.data.header_untertitel}
            </h2>
            <hr
              sx={{
                backgroundColor: '#fff',
                height: '4px',
                width: ['17rem', '', '12rem'],
                margin: '0.25rem 0 0 0',
                padding: '0',
                border: 0,
              }}
            />
          </div>
        </div>
      </div>

      <Container>
        <Styled.h1>{Kontakt.data.sec1_title}</Styled.h1>
        <div dangerouslySetInnerHTML={{__html: Kontakt.data.sec1_desc.html}} />

        <div sx={{maxWidth: '46rem', m: '0 auto', pt: '1rem'}}>
          <Form data={prismicForm} lang={lang} />
        </div>
      </Container>

      <Container>
        <Styled.h1
          sx={{
            pb: 4,
            textAlign: 'center',
          }}
        >
          {Kontakt.data.map_title}
        </Styled.h1>
        <div
          sx={{
            overflow: 'hidden',
            paddingBottom: '56.25%',
            position: 'relative',
            height: 0,
          }}
        >
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2707.1565891971304!2d8.820480716077935!3d47.27219341901109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ab9e6a48db42b%3A0xa5cd2f8050f79d62!2sSennweidstrasse%201A%2C%208608%20Bubikon!5e0!3m2!1sen!2sch!4v1602169530645!5m2!1sen!2sch"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            sx={{
              left: 0,
              top: 0,
              height: '100%',
              width: '100%',
              position: 'absolute',
              border: 0,
            }}
          />
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Kontakt($lang: String) {
    i18nPathname: allPrismicKontaktseite {
      nodes {
        uid
        lang
      }
    }

    Kontakt: prismicKontaktseite(lang: {eq: $lang}) {
      uid
      lang
      data {
        header_title
        header_untertitel
        sec1_title
        sec1_desc {
          html
        }

        map_title

        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }

    prismicForm(lang: {eq: $lang}) {
      lang
      ...FormInformation
    }
  }
`

export default KontaktSite
